<template>
  <div>
    <b-modal
      ref="alert"
      title="Notification"
      content-class="payment-modal"
      header-class="payment-modal-header"
      footer-class="payment-modal-footer"
      header-close-variant="light"
      :ok-only="true"
      ok-variant="primary"
      :ok-title="confirmButtonText"
      no-close-on-backdrop
    >
      <div class="active-icon">
        <check-mark-icon
          v-if="type === 'success'"
          class="text-success"
        />
        <close-circle-icon
          v-if="type === 'error'"
          class="text-danger"
        />
      </div>
      <h5 class="lm-modal-title">
        {{ title }}
      </h5>
      <hr style="max-width: 50%; display: block; margin: 1em auto;">
      <div>{{ text }}</div>
      <div v-html="html" />
    </b-modal>
  </div>
</template>

<script>
import CheckMarkIcon from 'vue-ionicons/dist/ios-checkmark-circle-outline'
import CloseCircleIcon from 'vue-ionicons/dist/ios-close-circle-outline'

/*
 * Bootstrap Modal
 * https://bootstrap-vue.org/docs/components/modal
 */
export default {
  name: 'Modal',
  components: {
    CheckMarkIcon,
    CloseCircleIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: 'OK'
    }
  },
  mounted () {
    this.$refs.alert.show()
  }
}
</script>

<style lang="scss">
  .payment-modal {
    .payment-modal-header {
      h5.modal-title {
        font-size: 1.2em;
      }
    }

    .payment-modal-title {
      font-size: 1.4em;
      line-height: 1.4;
      max-width: 80%;
      margin: 0.5em auto;
    }

    .lm-modal-title {
      margin-bottom: 15px;
    }

    .modal-body {
      color: #888888;
      h1, h2, h3, h4, h5, h6, .active-icon {
        text-align: center;
      }

      p {
        font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", 'sans-serif';
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .active-icon {
      font-size: 80px;
      line-height: 80px;
    }

    .payment-modal-footer {
      justify-content: center;
      .btn {
        font-size: 1.1em;
        padding-left: 1em;
        padding-right: 1em;
        font-family: Poppins, sans-serif;
      }
    }
  }
</style>

class OrderInvoiceUtils {
  /**
   * Get total from items
   * @param items
   * @return {number}
   */
  getTotal (items) {
    let amount = 0
    items.forEach(item => {
      amount += item.amount
    })

    return amount
  }

  /**
   * For Keypress
   * @param $event
   * @return {boolean}
   */
  isNumber ($event) {
    $event = ($event) || window.event
    const charCode = ($event.which) ? $event.which : $event.keyCode
    const isPeriod = charCode === 46
    if (isPeriod && $event.target.value.indexOf('.') > -1) {
      $event.preventDefault()
    } else if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      $event.preventDefault()
    } else {
      return true
    }
  }

  handleAmountPaste ($event) {
    const value = $event.clipboardData.getData('text')
    $event.target.value = parseFloat(value.replace(/[^0-9.]/g, ''))
    $event.preventDefault()
  }

  /**
   * Check if items are valid
   * @param {Array<object>} items
   * @param {object} context
   * @return {*}
   */

  validateItems (items, context) {
    context.errors = {}
    let isValid = true

    const notification = {
      group: 'notifications',
      title: 'All fields are required',
      text: 'Please fix the entries that need corrections, and press "process payment" to validate once more',
      type: 'error'
    }

    for (let index = 0; index < items.length; index++) {
      const invoice = items[index]
      for (const property in invoice) {
        if (!invoice[property]) {
          const key = `${index}.${property}`
          context.errors[key] = false
          isValid = false
        }
      }
    }

    if (!isValid) {
      context.$notify(notification)
    }

    return isValid
  }
}

export default OrderInvoiceUtils

<template>
  <div class="register-wrapper">
    <label>Create new account</label>

    <profile-form
      :form="form"
      :btn-text="'Create Account'"
      :show-password="true"
      :show-representative="true"
      :show-customer-id="false"
      :show-terms="true"
      :disabled="false"
      @submit="handleForm"
    />

    <div class="login">
      <router-link to="/">
        Have an account? Sign In
      </router-link>
    </div>
  </div>
</template>

<script>
import profileForm from './ProfileForm'

export default {
  name: 'Register',
  components: {
    profileForm
  },
  data () {
    return {
      form: {
        company_type: null,
        company_name: '',
        company_address: {
          street: '',
          city: '',
          state: null,
          zip: ''
        },
        contact_name: '',
        contact_phone_number: '',
        billing_address: {
          street: '',
          state: null,
          zip: '',
          country: 'USA'
        },
        email: '',
        password: '',
        repeat: '',
        market: null,
        account_representative: null,
        customer_id: null,
        company_id: null,
        isActive: false,
        isAdmin: true,
        users_list: []
      }
    }
  },
  methods: {
    handleForm () {
      if (this.form.password !== this.form.repeat) {
        return this.$notifyModal({
          title: 'Passwords Did Not Match',
          type: 'error',
          html: 'Your passwords should match, please revise your entry and try again',
          confirmButtonText: 'Got it'
        })
      }

      this.$http.post('/auth/register', { user: this.form })
        .then((res) => {
          if (res.success) {
            this.$notifyModal({
              title: 'Account Registered & Pending Review',
              type: 'success',
              html: `
                <p>Thanks for registering to the payment center.</p>
                <p>Your account has been submitted for review.  The account representative you selected and the payment support team will receive a notification and review your registration.</p>
                <p>Once they approve your account, you will be able to log in and process your payment</p>
              `,
              confirmButtonText: 'Okay, I will wait for approval'
            })
            this.$analyticsManager.logEvent('CreatedProfile', {
              companyName: this.form.company_name,
              email: this.form.email,
              accountRepresentative: this.form.account_representative
            })

            this.$router.push({ path: '/contact', query: { auth: true } })
          } else {
            this.$notifyModal({
              title: 'Account Creation Error',
              html: res.message,
              type: 'error',
              confirmButtonText: 'Ok, I will try again'
            })
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  label{
    text-transform: uppercase;
    font-weight: 900;
    color: #000;
    font-size: 24px;
    margin: 0;
  }

  .register-wrapper .login{
    text-align: center;
  }

  .register-wrapper .login a{
    color: #0B60A9;
    font-size: 12px;
  }

  @media (max-width: 991px) {
    /* .register-wrapper {
      padding: 0 20px;
    } */
  }

</style>

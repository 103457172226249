<template>
  <div class="container-fluid">
    <div
      v-if="displayPaymentForm"
      class="payment-wrapper"
    >
      <b-form
        class="payment-form-wrapper"
        @submit="pay"
      >
        <h2>
          Amount: {{ transaction.total | currency }}
        </h2>

        <b-form-group label="Address">
          <b-form-input
            v-model="transaction.address"
            type="text"
            required
            :disabled="isLoading"
            placeholder="Enter Address"
          />
        </b-form-group>

        <b-form-group label="City">
          <b-form-input
            v-model="transaction.city"
            type="text"
            required
            :disabled="isLoading"
            placeholder="Enter City"
          />
        </b-form-group>

        <b-form-group label="State">
          <b-form-input
            v-model="transaction.state"
            type="text"
            required
            :disabled="isLoading"
            placeholder="Enter State"
          />
        </b-form-group>

        <b-form-group label="Zip Code">
          <b-form-input
            v-model="transaction.zipCode"
            type="text"
            required
            :disabled="isLoading"
            placeholder="Enter Zip Code"
          />
        </b-form-group>

        <b-form-group label="Card Number">
          <b-form-input
            v-model="transaction.card_number"
            v-mask="'#### #### #### ####'"
            type="text"
            required
            :disabled="isLoading"
            placeholder="Enter Card Number"
          />
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="Expiration Date">
              <b-form-input
                v-model="transaction.expiry_date"
                v-mask="'##/##'"
                type="text"
                required
                :disabled="isLoading"
                placeholder="MM/YY"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="CVV">
              <b-form-input
                v-model="transaction.cvv"
                type="text"
                required
                :disabled="isLoading"
                placeholder="Enter CVV"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <button
          v-if="!isLoading"
          id="process_payment"
          type="submit"
          class="btn btn-primary"
        >
          Process Payment
        </button>

        <button
          v-if="isLoading"
          class="btn btn-primary"
        >
          <div class="spinner">
            <div class="bounce1" />
            <div class="bounce2" />
            <div class="bounce3" />
          </div>
        </button>
      </b-form>
    </div>

    <div
      v-if="!displayPaymentForm"
      class="payment-approved-message"
    >
      <h2>Thank you for your payment</h2>
      <div class="text">
        You will receive a confirmation email with all relevant payment details.
      </div>
      <div
        v-if="status === 'pending'"
        class="text text-alert"
      >
        <strong>Note: </strong> Your transaction is under review.
      </div>
      <div class="text">
        <strong>Transaction ID: </strong> {{ transactionID }}
      </div>
      <div
        id="check_activity"
        class="btn btn-primary"
        @click="navigate"
      >
        Check your latest activity
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayVue',
  data () {
    const isProduction = process.env.NODE_ENV === 'production'
    return {
      isLoading: false,
      displayPaymentForm: true,
      typeToPay: '',
      transaction: {
        address: '',
        city: '',
        state: '',
        zipCode: '',
        total: 0,
        card_number: isProduction ? '' : '4000000000000002',
        expiry_date: isProduction ? '' : '1224',
        cvv: isProduction ? '' : '1224',
        token: null,
        firstName: '',
        lastName: ''
      },
      paymentItems: [],
      transactionID: null,
      status: null
    }
  },
  computed: {
    firstName: function () {
      return this.customer.contact_name.split(/\s+/g)[0] || ''
    },
    lastName: function () {
      return this.customer.contact_name.split(/\s+/g)[1] || ''
    }
  },
  mounted () {
    const customer = JSON.parse(localStorage.getItem('user'))

    // Set Customer Details
    this.transaction.address = customer.billing_address.street
    this.transaction.zipCode = customer.billing_address.zip
    this.transaction.state = customer.billing_address.state
    this.transaction.city = customer.billing_address.city
    this.transaction.phone = customer.billing_address.contact_phone_number
    this.transaction.email = customer.email
    this.transaction.company = customer.company_name
    this.transaction.firstName = customer.contact_name.split(/\s+/g)[0] || ''
    this.transaction.lastName = customer.contact_name.split(/\s+/g)[1] || ''

    this.typeToPay = localStorage.getItem('typeToPay')
    this.paymentItems = JSON.parse(localStorage.getItem(this.typeToPay))
    this.transaction.total = this.paymentItems.reduce((accumulator, item) => {
      accumulator += item.amount
      return accumulator
    }, 0)

    // Sanitize the total
    this.transaction.total = Math.round((this.transaction.total) * 100) / 100
  },
  methods: {
    navigate () {
      this.$router.push(`/admin/activity/${this.typeToPay}`)
    },
    getTransactionToken: function () {
      const tokenRequest = {
        ssl_first_name: this.transaction.firstName,
        ssl_last_name: this.transaction.lastName,
        ssl_amount: this.transaction.total
      }
      return this.$http.post('/payment/token', tokenRequest).then((response) => {
        this.transaction.token = response.data
      })
    },

    pay: function (evt) {
      evt.preventDefault()
      this.isLoading = true

      this.getTransactionToken().then(() => {
        const t = this.transaction
        const paymentData = {
          ssl_company: t.company,
          ssl_email: t.email,
          ssl_avs_address: t.address,
          ssl_city: t.city,
          ssl_state: t.state,
          ssl_phone: t.phone,
          ssl_avs_zip: t.zipCode,
          ssl_txn_auth_token: t.token,
          ssl_card_number: t.card_number,
          ssl_exp_date: t.expiry_date,
          ssl_get_token: 'y',
          ssl_add_token: 'y',
          ssl_first_name: t.firstName,
          ssl_last_name: t.lastName,
          ssl_cvv2cvc2: t.cvv
        }

        window.ConvergeEmbeddedPayment.pay(paymentData, {
          onError: (response) => {
            console.log('onError.response', response)
            const notification = {
              title: 'Transaction Error',
              type: 'error',
              html: '',
              confirmButtonText: 'Confirm'
            }
            if (!response) {
              notification.text = 'We are having issues connecting to our payment gateway'
            } else if (typeof response === 'string') {
              notification.html = `
                <p>We received the following error from the merchant:</p>
                <p>${response}</p>
            `
            } else {
              notification.html = `
                <p>${response.errorCode}: ${response.errorName}</p>
                <p>${response.errorMessage}</p>
            `
            }
            this.$notifyModal(notification)
            this.isLoading = false
            const tCopy = Object.assign({}, this.transaction)

            // Ensure that the following keys do not
            // go to Amplitude or to Emails
            delete tCopy.card_number
            delete tCopy.token
            delete tCopy.cvv
            delete tCopy.expiry_date

            const messageToLog = {
              response: response,
              transaction: tCopy,
              items: this.paymentItems,
              type: this.typeToPay
            }
            this.$http.post('/notifications/transaction/error', messageToLog).catch(function (e) {
              console.log('/notifications/transaction/error.error', e)
            })
            this.$analyticsManager.logEvent('TransactionError', messageToLog)
          },
          onDeclined: (response) => {
            console.log('onDeclined.response', response)

            const notification = {
              title: 'Transaction Declined',
              type: 'error',
              html: '',
              confirmButtonText: 'Confirm'
            }

            const avsMap = {
              A: 'Your street address matches, but your zip code does not.  Please update your zip code and try again.',
              B: 'Your street address matches, but your postal code is in the wrong format.  Please update your payment details and try again.',
              C: 'Your street address and postal code are in the wrong formats.  Please update your address information and try again.',
              I: 'Your address information is not verified by the international issuer.  Please update your payment details and try again.',
              N: 'Your street address and zip code did not match. Please update your inputs and try again.',
              O: 'We received no response from the address verification service. Please try again.',
              R: 'Please try again, the system was unavailable or the request timed out.',
              U: 'If your card is a Visa the address information was unavailable.  If your card is a MasterCard the issuer was unregistered for CVC2 processing.  Please update your payment details and try again.',
              W: 'Your 9 digit zip matches, but the street address does not. Please update your payment details and try again.'
            }

            if (response.errorName) {
              notification.html = `
                <p>${response.errorCode}: ${response.errorName}</p>
                <p>${response.errorMessage}</p>
            `
            } else if (response.ssl_avs_response && avsMap[response.ssl_avs_response]) {
              notification.html = `
                <p>${avsMap[response.ssl_avs_response]}</p>
                <p>This error was generated by the address verification system (AVS).</p>
            `
            } else {
              notification.html = '<p>There was an error processing your payment, please check your payment details and try again.</p>'
            }

            this.$notifyModal(notification)
            this.isLoading = false

            // Ensure that the following keys do not
            // go to Amplitude or to Emails
            const tCopy = Object.assign({}, this.transaction)
            delete tCopy.card_number
            delete tCopy.token
            delete tCopy.cvv
            delete tCopy.expiry_date

            const messageToLog = {
              response: response,
              transaction: tCopy,
              items: this.paymentItems,
              type: this.typeToPay
            }

            this.$http.post('/notifications/transaction/declined', messageToLog).catch(function (e) {
              console.log('/notifications/transaction/declined.error', e)
            })
            this.$analyticsManager.logEvent('TransactionDeclined', messageToLog)
          },
          onApproval: (convergeResponse) => {
            console.log('onApproval.response', convergeResponse)
            const payload = {
              items: this.paymentItems,
              transactionResponse: convergeResponse
            }

            this.$http.post(`/${this.typeToPay}`, payload).then((res) => {
              this.transactionID = res.data.transaction_id
              this.status = res.data.status
              this.displayPaymentForm = false
              this.isLoading = false

              this.paymentItems.forEach((item) => {
                const properties = Object.assign({}, item)
                properties.type = this.typeToPay
                properties.cardType = convergeResponse.ssl_card_short_description
                properties.AVSCode = convergeResponse.ssl_avs_response
                this.$analyticsManager.logEvent('PaidForItem', properties)
              })

              localStorage.removeItem('orders')
              localStorage.removeItem('invoices')
              localStorage.removeItem('typeToPay')

              if (convergeResponse.ssl_avs_response && convergeResponse.ssl_avs_response === 'G') {
                this.$notifyModal({
                  title: 'Transaction Under Review',
                  type: 'success',
                  html: `
                    <p>Thank you! We are reviewing this payment given the fact that it is a card issued by a non-US issuer that does not participate in the AVS System</p>
                    <p><strong>Transaction ID: </strong>${this.transactionID}</p>
                `,
                  confirmButtonText: 'Confirm'
                })
              } else {
                this.$notifyModal({
                  title: 'Transaction Approved',
                  type: 'success',
                  html: `
                    <p>Thank you for your payment of $${convergeResponse.ssl_amount}! Your approval code is ${convergeResponse.ssl_approval_code}.</p>
                    <p><strong>Transaction ID: </strong>${this.transactionID}</p>
                `,
                  confirmButtonText: 'Confirm'
                })
              }
            }).catch((err) => {
              console.log(err)
              this.isLoading = false
            })
          }
        })
      })
    }
  }
}
</script>

<style>
  .payment-form-wrapper legend {
    font-weight: bold;
    color: #000;
  }
</style>

<style scoped>
.text-alert {
  padding: 10px 20px;
  background-color: red;
  color: #fff;
  border-radius: 6px;
}

.payment-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0 40px;
}

.payment-form-wrapper {
  flex: 1;
  max-width: 500px;
}

.payment-form-wrapper h2 {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}

.payment-form-wrapper button {
  width: 100%;
  margin-top: 10px;
  padding: 0.575rem 0.75rem;
}

.payment-approved-message {
  text-align: center;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-approved-message h2 {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}

.payment-approved-message .text {
  margin-bottom: 20px;
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 16px;
  height: 16px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

</style>

<template>
  <div class="row all-orders">
    <div class="col-md-12">
      <div
        v-if="orders.length > 0"
        class="table-responsive table-striped"
      >
        <table class="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Amount</th>
              <th>Station</th>
              <th>From</th>
              <th>To</th>
              <th>Advertiser</th>
              <th>Status</th>
              <th>Transaction</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in orders"
              :key="index"
            >
              <td>
                {{ order.orderNumber }}
              </td>
              <td class="text-right">
                {{ order.amount | currency }}
              </td>
              <td>
                {{ order.station }}
              </td>
              <td>
                {{ formatDate(order.from) }}
              </td>
              <td>
                {{ formatDate(order.to) }}
              </td>
              <td>
                {{ order.advertiser }}
              </td>
              <td class="text-capitalize">
                {{ order.status }}
              </td>
              <td>
                <strong @click="getTransaction(order)">Detail</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="orders.length === 0"
        class="message"
      >
        No orders processed yet
      </div>
    </div>

    <transaction-modal
      :modal-id="modalID"
      :transaction="transaction"
    />
  </div>
</template>

<script>
import transactionModal from '../common/TransactionModal'

export default {
  name: 'AllOrders',
  components: {
    transactionModal
  },
  data () {
    return {
      modalID: 'ordersTransactions',
      transaction: {},
      orders: []
    }
  },

  mounted () {
    this.$http.get('/orders')
      .then((res) => {
        this.orders = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    getTransaction (order) {
      this.$http.get(`/transactions/${order.transaction_id}?type=orders`)
        .then((res) => {
          this.transaction = res.data

          this.transaction.items = this.transaction.items.map((item) => {
            return {
              number: item.orderNumber,
              amount: item.amount
            }
          })
          this.$bvModal.show(this.modalID)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formatDate (d) {
      const date = new Date(d)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = '0' + dt
      }
      if (month < 10) {
        month = '0' + month
      }

      return month + '/' + dt + '/' + year
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all-orders {
  min-height: 450px;
}

.message {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
}

td strong {
  cursor: pointer;
  color: #0b60a9;
}
</style>

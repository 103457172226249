<template>
  <div class="login-wrapper">
    <form
      v-if="!showNotification"
      class="login-form"
      @submit="submit"
    >
      <label>Forgot Password?</label>
      <input
        v-model="email"
        type="email"
        placeholder="Email"
        required
      >
      <button
        type="submit"
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>

    <div
      v-if="!showNotification"
      class="register"
    >
      <router-link to="register">
        Don't have an account? Sign Up
      </router-link>
    </div>

    <div
      v-if="showNotification"
      class="notification"
    >
      <h3>Check your email to change your password</h3>
      <hr>
      <p>Expect an email from <strong>CreditCards@sbscorporate.com</strong> sent to the inbox at <strong>{{ email }}</strong> with a reset password link. From there you can reset your password.</p>
      <div class="btn-wrapper">
        <button
          class="btn btn-primary"
          @click="redirectUser"
        >
          Got It
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forgot',
  data () {
    return {
      email: '',
      showNotification: false
    }
  },
  methods: {
    submit (evt) {
      evt.preventDefault()
      this.$http.post('/auth/forgot', { email: this.email })
        .then((res) => {
          if (res.success) {
            // Notify user to check email to click the link to reset password
            this.showNotification = true
          } else {
            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'There was a problem with your request. Please try again',
            text: '',
            type: 'error'
          })
        })
    },
    redirectUser () {
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .login-wrapper {
    width: 100%;
    max-width: 450px;
  }

  .notification span,
  .login-wrapper label{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
  }

  .login-wrapper .login-form input{
    display: block;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
    font-size: 15px;
    height: 45px;
    border-radius: 0.25rem;
  }

  .login-wrapper .login-form a {
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #0B60A9;
    border-radius: 0.25rem;
  }

  .login-wrapper .login-form a:hover{
    background-color: #08487e;
    text-decoration: none;
  }

  .login-wrapper .register{
    text-align: center;
  }

  .login-wrapper .register a{
    color: #0B60A9;
    font-size: 12px;
  }

  .login-wrapper .register.forgot a{
    font-size: 10px;
  }

  button{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #0B60A9;
    border: none;
    width: 100%;
  }

  button:hover,
  button:active,
  button:active:focus{
    background-color: #08487e !important;
    border: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .notification {
    color: #08487e;
    font-size: 13px;
    padding: 15px;

  p {
    font-family: "Roboto","Helvetica Neue","Helvetica","Arial","sans-serif";
    font-size: 16px;
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 24px;
  }

  h3, p {
    color: #000;
  }

  span {
    display: block;
    margin-bottom: 10px;
  }

  .btn-wrapper button {
    margin-bottom: 0;
  }
}
</style>

<template>
  <div class="payment-header">
    <div class="row">
      <div class="col-md-12">
        <div class="header">
          <div class="title">
            {{ label }}
          </div>
          <div class="total">
            {{ total | currency }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="total-wrapper">
          <button
            class="btn btn-secondary btn-sm"
            @click="add"
          >
            ADD ROW
          </button>
          <button
            class="btn btn-secondary btn-sm"
            @click="process"
          >
            PROCESS PAYMENT
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentHeader',
  props: {
    label: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
    add () {
      this.$emit('add')
    },
    process () {
      this.$emit('process')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.payment-header {
  position: fixed;
  max-width: 1400px;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  top: 80px;
  border-bottom: 1px solid #e2e2e2;
  padding: 20px 30px 40px;
}

.total-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="header">
          <div class="title">
            Activity
          </div>
          <ul>
            <li>
              <router-link to="/admin/activity/orders">
                Orders
              </router-link>
            </li>
            <li>
              <router-link to="/admin/activity/invoices">
                Invoices
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Activity',
  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin-bottom: 30px;
}

.header ul {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.header ul li a {
  padding: 8px 20px;
  color: #000;
  border-radius: 5px;
  margin: 0 8px;
  font-size: 15px;
  font-weight: 400;
}

.header ul li a.router-link-active {
    background-color: #adadad;
    color: #fff;
}

.header ul li a:hover {
  text-decoration: none;
}

.header ul li:last-child a {
  margin-right: 0;
}

@media (max-width: 768px) {
  .header ul li a {
    font-size: 13px;
    margin: 0 5px;
    padding: 8px 10px;
  }
}
</style>

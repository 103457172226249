<template>
  <div class="row all-invoices">
    <div class="col-md-12">
      <div
        v-if="invoices.length > 0"
        class="table-responsive table-striped"
      >
        <table class="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Station</th>
              <th>Advertiser</th>
              <th>Status</th>
              <th>Transaction</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(invoice, index) in invoices"
              :key="index"
            >
              <td>
                {{ invoice.invoiceNumber }}
              </td>
              <td>
                {{ formatDate(invoice.date) }}
              </td>
              <td class="text-right">
                {{ invoice.amount | currency }}
              </td>
              <td>
                {{ invoice.station }}
              </td>
              <td>
                {{ invoice.advertiser }}
              </td>
              <td class="text-capitalize">
                {{ invoice.status }}
              </td>
              <td>
                <strong @click="getTransaction(invoice)">Detail</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="invoices.length === 0"
        class="message"
      >
        No invoices processed yet
      </div>
    </div>

    <transaction-modal
      :modal-id="modalID"
      :transaction="transaction"
    />
  </div>
</template>

<script>
import transactionModal from '../common/TransactionModal'

export default {
  name: 'AllInvoices',
  components: {
    transactionModal
  },
  data () {
    return {
      modalID: 'invoicesTransactions',
      transaction: {},
      invoices: []
    }
  },

  mounted () {
    this.$http.get('/invoices')
      .then((res) => {
        this.invoices = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    getTransaction (invoice) {
      this.$http.get(`/transactions/${invoice.transaction_id}?type=invoices`)
        .then((res) => {
          this.transaction = res.data

          this.transaction.items = this.transaction.items.map((item) => {
            return {
              number: item.invoiceNumber,
              amount: item.amount
            }
          })
          this.$bvModal.show(this.modalID)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formatDate (d) {
      const date = new Date(d)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = '0' + dt
      }
      if (month < 10) {
        month = '0' + month
      }

      return month + '/' + dt + '/' + year
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all-invoices {
  min-height: 450px;
}

.message {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
}

td strong {
  cursor: pointer;
  color: #0b60a9;
}
</style>

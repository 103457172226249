<template>
  <div class="main-wrapper">
    <div class="menu-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="menu-inner-wrapper">
              <div class="logo">
                <img
                  src="../../assets/sbs-blue-logo.svg"
                  alt=""
                >
              </div>

              <ul class="menu menu-desktop">
                <li>
                  <router-link to="/admin/orders">
                    Pay Orders
                  </router-link>
                </li>
                <li>
                  <router-link to="/admin/invoices">
                    Pay Invoices
                  </router-link>
                </li>
                <li>
                  <router-link to="/admin/profile">
                    Profile
                  </router-link>
                </li>
                <li>
                  <router-link to="/admin/activity">
                    Activity
                  </router-link>
                </li>
                <li
                  class="logout"
                  @click="logout"
                >
                  Logout
                </li>
              </ul>

              <div
                class="menu-mobile-icon"
                @click="displayMenuMobile = true"
              >
                <img src="../../assets/menu.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="displayMenuMobile"
      class="menu-mobile"
    >
      <div
        class="close-menu"
        @click="displayMenuMobile = false"
      >
        <img src="../../assets/close.png">
      </div>

      <ul class="menu-mobile-wrapper">
        <li>
          <router-link
            to="/admin/orders"
            @click.native="displayMenuMobile = false"
          >
            Pay Orders
          </router-link>
        </li>
        <li>
          <router-link
            to="/admin/invoices"
            @click.native="displayMenuMobile = false"
          >
            Pay Invoices
          </router-link>
        </li>
        <li>
          <router-link
            to="/admin/profile"
            @click.native="displayMenuMobile = false"
          >
            Profile
          </router-link>
        </li>
        <li>
          <router-link
            to="/admin/activity"
            @click.native="displayMenuMobile = false"
          >
            Activity
          </router-link>
        </li>
        <li
          class="logout"
          @click="logout"
        >
          Logout
        </li>
      </ul>
    </div>

    <main class="content-wrapper">
      <router-view />
    </main>

    <main-footer />
  </div>
</template>

<script>
import Axios from 'axios'
import mainFooter from '../common/Footer'

export default {
  name: 'Admin',
  components: {
    mainFooter
  },
  data () {
    return {
      selected: 'orders',
      formData: new FormData(),
      modules: [],
      user: null,
      windowWidth: 0,
      mobileMenu: false,
      displayMobileMenu: false,
      displayClose: false,
      displayMenuMobile: false
    }
  },

  mounted () {
    this.user = localStorage.getItem('user')
  },

  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('orders')
      localStorage.removeItem('invoices')
      localStorage.removeItem('typeToPay')
      Axios.defaults.headers.common.Authorization = 'Bearer'
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.menu-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  z-index: 2;
}

.menu-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.menu-inner-wrapper .logo {
  max-width: 80px;
}

.menu-inner-wrapper .logo img {
  width: 100%;
}

.close-menu {
  position: fixed;
  top: 40px;
  right: 40px;
}

.close-menu img {
  max-width: 30px;
}

.menu-mobile-icon,
.menu-mobile {
  display: none;
}

.menu-mobile-icon img {
  max-width: 32px;
}

.menu-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}

.menu-mobile .menu-mobile-wrapper {
  left: 0;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 40px;
  list-style-type: none;
}

.menu-mobile .menu-mobile-wrapper li {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.menu-mobile .menu-mobile-wrapper li a {
  color: #000;
  border-radius: 5px;
}

.menu-mobile .menu-mobile-wrapper li a.router-link-active {
  color: #0B60A9;
  text-decoration: none;
}

.menu-inner-wrapper .menu-desktop {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.menu-inner-wrapper .menu-desktop li.logout,
.menu-inner-wrapper .menu li a {
  padding: 8px 20px;
  color: #0B60A9;
  border-radius: 5px;
  margin: 0 8px;
}

.menu-inner-wrapper .menu-desktop li.logout {
  margin: 0;
}

.menu-inner-wrapper .menu-desktop li a.router-link-active{
  background-color: #0B60A9;
  color: #fff;
}

.menu-inner-wrapper .menu-desktop li a:hover {
  text-decoration: none;
}

.menu-inner-wrapper .logout {
  cursor: pointer;
}

.content-wrapper {
  margin-top: 80px;
  padding: 30px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #000;
  font-size: 28px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .menu-inner-wrapper .menu-desktop {
    display: none;
  }

  .menu-mobile-icon,
  .menu-mobile {
    display: block;
  }

  .header {
    font-size: 22px;
  }
}
</style>

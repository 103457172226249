<template>
  <div class="register-wrapper">
    <label>Review & Approve Account</label>

    <profile-form
      v-if="Object.keys(form).length > 0"
      :form="form"
      :btn-text="!form.isActive ? 'Approve Account' : 'Account Approved already'"
      :show-customer-id="false"
      :show-password="false"
      :show-terms="false"
      :disabled="true"
      :show-representative="true"
      @submit="handleForm"
    />
  </div>
</template>

<script>
import profileForm from './ProfileForm'

export default {
  name: 'Approve',
  components: {
    profileForm
  },
  data () {
    return {
      form: {}
    }
  },

  mounted () {
    this.userID = this.$route.params.id
    this.$http.get(`/profiles/${this.userID}`)
      .then((res) => {
        if (res.success) {
          this.form = res.data
        } else {
          this.$notifyModal({
            title: 'Something Went Wrong',
            html: '<p>Please refresh and try again or contact technical support.</p>',
            type: 'error',
            confirmButtonText: 'Confirm'
          })
        }
      })
      .catch((err) => {
        console.log(err)
        this.$notifyModal({
          title: 'Something Went Wrong',
          html: '<p>Please refresh and try again or contact technical support.</p>',
          type: 'error',
          confirmButtonText: 'Confirm'
        })
      })
  },

  methods: {
    handleForm () {
      const payload = {
        userID: this.userID,
        userEmail: this.form.email
      }
      this.$http.post('/auth/approve', payload)
        .then((res) => {
          if (res.success) {
            this.$analyticsManager.logEvent('ApprovedProfile', payload)
            this.form.isActive = true
            this.$notifyModal({
              title: 'Account Successfully Approved',
              html: `<p>${payload.userEmail} will receive an email prompting them to log in and make a payment.</p>`,
              type: 'success',
              confirmButtonText: 'Confirm'
            })
          } else {
            this.$notifyModal({
              group: 'Something Went Wrong',
              html: `<p>${res.message}</p>`,
              type: 'error',
              confirmButtonText: 'Confirm'
            })
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  label{
    text-transform: uppercase;
    font-weight: 900;
    color: #000;
    font-size: 24px;
    margin: 0;
  }

  .register-wrapper .login{
    text-align: center;
  }

  .register-wrapper .login a{
    color: #0B60A9;
    font-size: 12px;
  }

  @media (max-width: 991px) {
    .register-wrapper {
      padding: 0 20px;
    }
  }

</style>

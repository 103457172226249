<template>
  <b-modal
    :id="modalId"
    hide-footer
  >
    <div class="text-center">
      <div class="modal-text">
        Amount to Pay
      </div>
      <div class="modal-text last">
        {{ total | currency }}
      </div>
      <div>
        <b-form-checkbox
          id="consent_terms_conditions"
          v-model="consentToTermsAndConditions"
        >
          I agree with the <a
            target="_blank"
            href="https://www.lamusica.com/termsofuse"
          >terms and conditions.</a>
        </b-form-checkbox>
      </div>
      <hr>
      <b-button
        id="confirm_and_pay"
        class="btn-custom"
        :disabled="!consentToTermsAndConditions"
        @click="sendToPaymentForm"
      >
        Confirm and Pay
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'PaymentModal',
  props: {
    modalId: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    const customer = JSON.parse(localStorage.getItem('user'))
    return {
      customer,
      consentToTermsAndConditions: false
    }
  },
  computed: {
  },

  mounted () {
  },

  methods: {
    sendToPaymentForm () {
      localStorage.setItem('typeToPay', this.type)
      this.$router.push({
        name: 'Pay'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-text {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}

.modal-text.last {
  margin-bottom: 20px;
  font-size: 36px;
}

.modal-terms {
  font-size: 13px;
}

.btn.btn-custom {
  background-color: #0B60A9;
  padding: 0.575rem 0.75rem;
  margin-bottom: 10px;
  width: 100%;
}
</style>

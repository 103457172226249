import Vue from 'vue'
import Router from 'vue-router'
import Axios from 'axios'
import Http from '../components/common/http.js'

// Homepage Routes
import Homepage from '@/components/home/Homepage'
import Login from '@/components/home/Login'
import Register from '@/components/home/Register'
import Forgot from '@/components/home/Forgot'
import NewPassword from '@/components/home/NewPassword'
import Approve from '@/components/home/Approve'
import Contact from '@/components/home/Contact'
import Terms from '@/components/home/Terms'
import NotFound from '@/components/common/404'

// Admin Routes
import Admin from '@/components/admin/Admin'
import Orders from '@/components/admin/Orders'
import AllOrders from '@/components/admin/AllOrders'
import Invoices from '@/components/admin/Invoices'
import AllInvoices from '@/components/admin/AllInvoices'
import Profile from '@/components/admin/Profile'
import Activity from '@/components/admin/Activity'
import Pay from '@/components/admin/Pay'

Vue.use(Router)
const http = new Http()

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/index.php', redirect: '/'
    },
    {
      path: '/',
      component: Homepage,
      children: [
        {
          path: '/',
          component: Login,
          meta: {
            checkAuth: true
          }
        },
        {
          path: 'register',
          component: Register
        },
        {
          path: 'Forgot',
          component: Forgot
        },
        {
          path: '/recover/:userID/:token',
          component: NewPassword
        },
        {
          path: '/approve/:id',
          component: Approve
        },
        {
          path: '/contact',
          component: Contact
        },
        {
          path: '/terms',
          component: Terms
        }
      ]
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      children: [
        {
          path: 'orders',
          component: Orders,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'invoices',
          component: Invoices,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'profile',
          component: Profile,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'activity',
          component: Activity,
          redirect: { name: 'All Orders' },
          children: [
            {
              name: 'All Orders',
              path: 'orders',
              component: AllOrders,
              meta: {
                requiresAuth: true
              }
            },
            {
              name: 'All Invoices',
              path: 'invoices',
              component: AllInvoices,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'pay',
          name: 'Pay',
          component: Pay,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // Before changing routes, log previous route
  if (Vue.prototype.$analyticsManager) {
    Vue.prototype.$analyticsManager.viewedPage()
  }

  if (to.fullPath === '/') {
    if (localStorage.getItem('token')) {
      http.post('/auth/validate')
        .then((res) => {
          if (res.success) {
            next({
              path: '/admin/orders'
            })
          } else {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('orders')
            Axios.defaults.headers.common.Authorization = 'Bearer'
            next()
          }
        })
        .catch((err) => {
          console.log(err)
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('orders')
          Axios.defaults.headers.common.Authorization = 'Bearer'
          next({
            path: '/'
          })
        })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/'
      })
    } else {
      http.post('/auth/validate')
        .then((res) => {
          if (res.success) {
            next()
          } else {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('orders')
            Axios.defaults.headers.common.Authorization = 'Bearer'
            next({
              path: '/'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('orders')
          Axios.defaults.headers.common.Authorization = 'Bearer'
          next({
            path: '/'
          })
        })
    }
  } else {
    next()
  }
})

export default router

<template>
  <b-modal
    :id="modalId"
    size="md"
    hide-footer
  >
    <div class="row-wrapper">
      <div class="title">
        Transaction No.
      </div>
      <div class="value">
        {{ transaction._id }}
      </div>
    </div>

    <div class="row-wrapper">
      <div class="title">
        Transaction Amount
      </div>
      <div class="value">
        {{ transaction.amount | currency }}
      </div>
    </div>

    <div
      v-if="transaction.response"
      class="row-wrapper"
    >
      <div class="title">
        Credit Card Number
      </div>
      <div class="value">
        {{ transaction.response.ssl_card_number }}
      </div>
    </div>

    <div
      v-if="transaction.response"
      class="row-wrapper"
    >
      <div class="title">
        Approval Code
      </div>
      <div class="value">
        {{ transaction.response.ssl_approval_code }}
      </div>
    </div>

    <div
      v-if="transaction.response"
      class="row-wrapper"
    >
      <div class="title">
        Transaction Status
      </div>
      <div class="value">
        {{ transaction.status }}
      </div>
    </div>

    <div
      v-if="transaction.response"
      class="row-wrapper"
    >
      <div class="title">
        Transaction Date & Time
      </div>
      <div class="value">
        {{ transaction.response.ssl_txn_time }}
      </div>
    </div>

    <div class="row-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              No.
            </th>
            <th scope="col">
              Type
            </th>
            <th scope="col">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(items, index) in transaction.items"
            :key="index"
          >
            <td>
              {{ items.number }}
            </td>
            <td>
              {{ transaction.type }}
            </td>
            <td>
              {{ items.amount | currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'PaymentModal',
  props: {
    modalId: {
      type: String,
      default: ''
    },
    transaction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },

  mounted () {
  },

  methods: {
    formatDate (date) {
      const d = new Date(date)
      return d.toDateString()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row-wrapper {
  margin-bottom: 25px;
}

.row-wrapper .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="header">
          <div class="title">
            Update Profile
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <profile-form
          v-if="Object.keys(user).length > 0"
          :form="user"
          :show-password="false"
          :show-representative="false"
          :show-customer-id="true"
          :show-terms="true"
          :disabled="false"
          :btn-text="'Update'"
          @submit="handleForm"
        />
      </div>

      <div
        v-if="user.isAdmin"
        class="col-md-7"
      >
        <div class="add-users-wrapper">
          <div class="header-users">
            <div
              v-if="user.users_list && user.users_list.length === 0"
              class="title"
            >
              No users yet. Start adding...
            </div>
            <div
              v-if="user.users_list && user.users_list.length > 0"
              class="title"
            >
              User List
            </div>
            <button
              class="btn btn-primary"
              @click="openProfileModal"
            >
              Add
            </button>
          </div>
        </div>

        <div
          v-if="user.users_list && user.users_list.length > 0"
          class="users-wrapper"
        >
          <div class="user-wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    Name
                  </th>
                  <th scope="col">
                    Email
                  </th>
                  <th scope="col">
                    Customer ID
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(company_user, index) in user.users_list"
                  :key="company_user.customer_id"
                >
                  <td>
                    {{ company_user.contact_name }}
                  </td>
                  <td>
                    {{ company_user.email }}
                  </td>
                  <td>
                    {{ company_user.customer_id }}
                  </td>
                  <td>
                    <button
                      class="btn btn-danger btn-delete-user"
                      @click="openRemoveModal(index, company_user)"
                    >
                      <i class="fa fa-close" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-md-12">
        <div class="header">
          <div class="title">
            Reset Password
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <b-form
          class="bootstrap-form"
          @submit="onSubmit"
        >
          <b-row>
            <b-col>
              <b-form-input
                v-model="password.new"
                type="password"
                required
                trim
                placeholder="Enter New Password"
              />
            </b-col>
            <b-col>
              <b-form-input
                v-model="password.repeat"
                type="password"
                required
                trim
                placeholder="Repeat New Password"
              />
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
          >
            Reset Password
          </b-button>
        </b-form>
      </div>
    </div>

    <b-modal
      ref="profileModal"
      hide-footer
      @hidden="resetFormModal"
    >
      <profile-form
        :form="form"
        :btn-text="'Add User'"
        :show-password="true"
        :show-representative="false"
        :show-customer-id="false"
        :show-terms="true"
        :disabled="false"
        @submit="createUser"
      />
    </b-modal>

    <b-modal
      ref="removeModal"
      @ok="removeUser"
      @hidden="resetModal"
    >
      Are you sure you want to remove this user?
    </b-modal>
  </div>
</template>

<script>
import profileForm from '../home/ProfileForm'

export default {
  name: 'Profile',
  components: {
    profileForm
  },
  data () {
    return {
      user: {},
      form: {
        company_type: null,
        company_name: '',
        company_address: {
          street: '',
          city: '',
          state: null,
          zip: ''
        },
        contact_name: '',
        contact_phone_number: '',
        billing_address: {
          street: '',
          state: null,
          zip: '',
          country: 'USA'
        },
        email: '',
        password: '',
        repeat: '',
        account_representative: 'I Don\'t Know',
        customer_id: null,
        company_id: null,
        isActive: true,
        isAdmin: false,
        users_list: null
      },
      toDelete: {
        index: null,
        companyUser: null
      },
      password: {
        new: null,
        repeat: null
      }
    }
  },

  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'))
  },

  methods: {
    handleForm () {
      this.$http.put('/profiles', { profile: this.user })
        .then((res) => {
          this.$analyticsManager.logEvent('UpdatedProfile', {})
          localStorage.setItem('user', JSON.stringify(this.user))

          this.$notify({
            group: 'notifications',
            title: 'User Successfully Updated',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
        })
    },
    resetFormModal () {
      this.resetForm()
    },
    openProfileModal () {
      this.$refs.profileModal.show()
    },
    createUser () {
      if (this.form.password !== this.form.repeat) {
        return this.$notify({
          group: 'notifications',
          title: 'Passwords should match',
          text: '',
          type: 'error'
        })
      }

      this.form.company_id = this.user._id

      this.$http.post('/auth/add', { user: this.form })
        .then((res) => {
          if (res.success) {
            this.user.users_list.push({
              email: res.data.email,
              contact_name: res.data.contact_name,
              customer_id: res.data.customer_id
            })

            localStorage.setItem('user', JSON.stringify(this.user))

            this.$notify({
              group: 'notifications',
              title: 'User created successfully.',
              text: '',
              type: 'success'
            })

            this.$refs.profileModal.hide()
          } else {
            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
        })
    },
    resetModal () {
      this.toDelete.index = null
      this.toDelete.companyUser = null
    },
    openRemoveModal (index, companyUser) {
      this.toDelete.index = index
      this.toDelete.companyUser = companyUser
      this.$refs.removeModal.show()
    },
    removeUser (evt) {
      evt.preventDefault()

      this.$http.delete('/auth', { params: { email: this.toDelete.companyUser.email } })
        .then((res) => {
          this.user.users_list.splice(this.toDelete.index, 1)
          localStorage.setItem('user', JSON.stringify(this.user))

          this.$notify({
            group: 'notifications',
            title: 'User successfully deleted',
            text: '',
            type: 'success'
          })

          this.$refs.removeModal.hide()
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong',
            text: '',
            type: 'error'
          })
        })
    },
    resetForm () {
      this.form = {
        company_type: null,
        company_name: '',
        company_address: {
          street: '',
          city: '',
          state: null,
          zip: ''
        },
        contact_name: '',
        contact_phone_number: '',
        billing_address: {
          street: '',
          state: null,
          zip: '',
          country: 'USA'
        },
        email: '',
        password: '',
        repeat: '',
        account_representative: 'I Don\'t Know',
        customer_id: null,
        company_id: null,
        isActive: true,
        isAdmin: false,
        users_list: null
      }
    },
    onSubmit (evt) {
      evt.preventDefault()
      console.log(this.password)
      if (this.password.new !== this.password.repeat) {
        return this.$notify({
          group: 'notifications',
          title: 'Passwords should match. Please try again :(',
          text: '',
          type: 'error'
        })
      }

      const user = JSON.parse(localStorage.getItem('user'))

      this.$http.post('/auth/update-password', { userID: user._id, password: this.password.new })
        .then((res) => {
          localStorage.setItem('token', res.data.token)
          this.password.new = null
          this.password.repeat = null
          this.$notify({
            group: 'notifications',
            title: 'Password successfully updated',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
          this.password.new = null
          this.password.repeat = null
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header {
    margin-bottom: 20px;
  }

  .add-users-wrapper {
    padding: 10px 20px;
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .add-users-wrapper .header-users {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add-users-wrapper .header-users .title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-right: 20px;
  }

  .btn-danger.btn-delete-user {
    padding: 0 0.4rem;
  }
</style>

<template>
  <div class="home-wrapper">
    <div class="home">
      <div class="overlay" />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <img
                src="../../assets/sbs-blue-logo.svg"
                alt="Spanish Broadcasting System Logo"
              >
              <div class="text">
                SBS Payment Center
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <div class="sidebar-wrapper">
        <router-view />
      </div>
    </div>

    <main-footer />
  </div>
</template>

<script>
import mainFooter from '../common/Footer'

export default {
  name: 'Homepage',
  components: {
    mainFooter
  },
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .home-wrapper .home {
    background-image: url('../../assets/home-bg.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .home-wrapper .home .content {
    padding: 20px 0;
  }

  .home-wrapper .home .content img{
    max-width: 200px;
    margin-bottom: 15px;
  }

  .home-wrapper .home .content .text{
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }

  .home-wrapper .sidebar {
    position: relative;
  }
  .home-wrapper .sidebar .sidebar-wrapper{
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    min-height: 650px;
  }

  .overlay{
    position: absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4)
  }

  .home-wrapper .footer {
    background-color: #f7f7f7;
    padding: 40px 0;
    border-top: 1px solid #f1f1f1;
  }

  .home-wrapper .footer .customer-service-wrapper {
    color: #000;
  }

  .home-wrapper .footer .customer-service-wrapper .customer-service-email {
    font-weight: bold;
    color: #0b60a9;
  }

  .home-wrapper .footer .menu {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .home-wrapper .footer .menu li {
    margin: 0 10px;
    cursor: pointer;
  }

  .home-wrapper .footer .menu li:hover {
    color: #0b60a9;
  }

   @media (max-width: 768px) {
    .home-wrapper .footer .menu li {
      margin: 0 6px;
      font-size: 12px;
    }
  }

  @media (max-width: 991px) {
  }
</style>

<template>
  <div class="container-fluid">
    <div
      v-if="isAuth"
      class="row"
    >
      <div class="col-md-12">
        <div class="alert">
          <p><strong>Note:</strong> Your account has been submitted for review to your account executive and the payment support staff. Once your account approved you will be able to log in and make payments.</p><br>
          <p>If this is an emergency please contact your Account Executive for immediate access or email <strong>creditcards@sbscorporate.com</strong> if you are unable to reach your account executive.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="header">
          <div class="title">
            Contact Information
          </div>
          <router-link to="/">
            &lt; Back
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="subtitle">
          <div>Markets</div>
          <div class="separator" />
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="(market, index) in markets"
        :key="index"
        class="col-md-4"
      >
        <div class="contact-wrapper">
          <div class="contact-title">
            {{ market.name }}
          </div>
          <address class="contact-phone">
            {{ market.address }} <br>
            {{ market.address2 }} <br v-if="market.address2">
            {{ market.city }}, {{ market.state }} {{ market.zipCode }} <br>
            Phone: {{ market.phone }} <br>
            <span v-if="market.fax">Fax: {{ market.fax }}</span>
          </address>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      isAuth: false,
      markets: [
        {
          name: 'New York',
          address: '11 East 26th Street',
          city: 'New York',
          state: 'NY',
          zipCode: '10010',
          phone: '212-541-9200 ext. 661',
          fax: '212-541-8535'
        },
        {
          name: 'Miami',
          address: 'Raul Alarcón Broadcast Center',
          address2: '7007 N.W. 77th Ave.',
          city: 'Medley',
          state: 'FL',
          zipCode: '33166',
          phone: '305-444-9292',
          fax: '305-883-7701'
        },
        {
          name: 'Puerto Rico',
          address: 'Calle Frances',
          city: 'Guaynabo',
          state: 'Puerto Rico',
          zipCode: '00966',
          phone: '787-622-9700'
        },
        {
          name: 'Los Angeles',
          address: '5055 Wilshire Blvd',
          address2: 'Suite 720',
          city: 'Los Angeles',
          state: 'CA',
          zipCode: '90036',
          phone: '310-229–3234'
        },
        {
          name: 'San Francisco',
          address: '675 North 1st Street',
          address2: 'Suite 250',
          city: 'San Jose',
          state: 'CA',
          zipCode: '95112',
          phone: '408-546-4000',
          fax: '408-546-4041'
        },
        {
          name: 'Chicago',
          address: '150 N. Michigan Ave.',
          address2: 'Suite 1040',
          phone: '312-920-9515',
          city: 'Chicago',
          state: 'IL',
          zipCode: '60601'
        }
      ]
    }
  },

  mounted () {
    this.isAuth = this.$route.query.auth
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header a {
  font-size: 14px;
  color: #2a70b1;
}

.header a:hover {
  text-decoration: none;
}

.subtitle,
.title {
  color: #000;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 24px;
}

.subtitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  text-transform: inherit;
  margin-top: 10px;
  margin-bottom: 20px;
}

.subtitle .separator {
  flex: 1;
  height: 1px;
  margin-left: 20px;
  background-color: #e6e6e6;
}

.alert {
  background-color: #2870b1;
  color: #fff;
  font-size: 16px;
  margin-bottom: 40px;
  padding: 1.85rem 1.85rem;
}

.contact-wrapper {
  margin-bottom: 40px;
}

.contact-wrapper .contact-title {
  color: #2970b1;
  font-weight: bold;;
}
</style>

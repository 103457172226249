import Axios from 'axios'

export default class Http {
  constructor () {
    this.url = process.env.VUE_APP_ROOT_API
    Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  get (endPoint, params) {
    let url = null

    if (endPoint.indexOf('http') === 0) {
      url = endPoint
    } else {
      url = this.url + endPoint
    }
    return Axios.get(url, params).then((res) => res.data)
  }

  post (endPoint, data, config) {
    console.log(this, this.url)

    return Axios.post(this.url + endPoint, data, config).then((res) => res.data)
  }

  put (endPoint, data) {
    return Axios.put(this.url + endPoint, data).then((res) => res.data)
  }

  delete (endPoint, params) {
    return Axios.delete(this.url + endPoint, params).then((res) => res.data)
  }
}

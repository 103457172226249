<template>
  <b-form
    class="bootstrap-form"
    @submit="onSubmit"
  >
    <div class="sub-label first-sub-label">
      <div>Company Info</div>
      <div class="separator" />
    </div>
    <b-row>
      <b-col>
        <b-form-select
          v-model="form.company_type"
          :options="companyType"
          :disabled="disabled"
          required
        />
      </b-col>

      <b-col>
        <b-form-input
          v-model="form.company_name"
          type="text"
          :disabled="disabled"
          trim
          required
          placeholder="Name"
        />
      </b-col>
    </b-row>

    <b-form-input
      v-model="form.company_address.street"
      type="text"
      :disabled="disabled"
      required
      trim
      placeholder="Street"
    />

    <b-row>
      <b-col>
        <b-form-input
          v-model="form.company_address.city"
          type="text"
          :disabled="disabled"
          required
          trim
          placeholder="City"
        />
      </b-col>

      <b-col>
        <b-form-select
          id="company_state"
          v-model="form.company_address.state"
          :options="states"
          :disabled="disabled"
          required
          trim
        />
      </b-col>

      <b-col>
        <b-form-input
          v-model="form.company_address.zip"
          v-mask="'#####'"
          type="number"
          :disabled="disabled"
          required
          placeholder="Zip Code"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-input
          id="contact_name"
          v-model="form.contact_name"
          type="text"
          :disabled="disabled"
          required
          trim
          placeholder="Contact Name"
        />
      </b-col>

      <b-col>
        <b-form-input
          v-model="form.contact_phone_number"
          v-mask="'(###) ###-####'"
          type="text"
          :disabled="disabled"
          required
          placeholder="Contact Phone #"
        />
      </b-col>
    </b-row>

    <div class="sub-label">
      <div>Billing Info</div>
      <div class="separator" />
    </div>

    <b-alert
      variant="info"
      :show="true"
    >
      <strong>Note:</strong> Address <u>must match with the cardholder records</u> as registered with the pertaining issuing bank, otherwise transactions may result as <strong>VOID</strong>
    </b-alert>

    <b-form-input
      v-model="form.billing_address.street"
      type="text"
      :disabled="disabled"
      required
      trim
      placeholder="Street"
    />

    <b-row>
      <b-col>
        <b-form-input
          v-model="form.billing_address.city"
          type="text"
          :disabled="disabled"
          required
          trim
          placeholder="City"
        />
      </b-col>

      <b-col>
        <b-form-select
          id="billing_state"
          v-model="form.billing_address.state"
          :options="states"
          :disabled="disabled"
          required
        />
      </b-col>

      <b-col>
        <b-form-input
          v-model="form.billing_address.zip"
          v-mask="'#####'"
          type="number"
          :disabled="disabled"
          required
          placeholder="Zip Code"
        />
      </b-col>
    </b-row>

    <b-form-select
      v-model="form.billing_address.country"
      :options="countries"
      :disabled="disabled"
      required
    />

    <div class="sub-label">
      <div>Login Info</div>
      <div class="separator" />
    </div>
    <b-form-input
      v-model="form.email"
      type="email"
      :disabled="disabled"
      required
      trim
      placeholder="Email"
    />

    <b-row v-if="showPassword">
      <b-col>
        <b-form-input
          v-model="form.password"
          type="password"
          :disabled="disabled"
          required
          trim
          placeholder="Password"
        />
      </b-col>

      <b-col>
        <b-form-input
          v-model="form.repeat"
          type="password"
          :disabled="disabled"
          required
          trim
          placeholder="Repeat Password"
        />
      </b-col>
    </b-row>

    <div
      v-if="showRepresentative"
      class="sub-label"
    >
      <div>Account Representative</div>
      <div class="separator" />
    </div>

    <div v-if="showRepresentative && !disabled">
      <b-form-select
        v-if="showRepresentative"
        v-model="form.market"
        :options="markets_select"
        required
        @change="marketSelected"
      />

      <b-form-select
        v-if="form.market"
        v-model="form.account_representative"
        :options="account_representatives_select"
        required
      />
    </div>

    <div v-if="showRepresentative && disabled">
      <b-form-input
        v-model="form.account_representative"
        type="Text"
        :disabled="disabled"
        required
        trim
        placeholder="Account Representative"
      />
    </div>

    <div
      v-if="showCustomerId"
      class="sub-label"
    >
      <div>Customer ID</div>
      <div class="separator" />
    </div>

    <b-form-input
      v-if="showCustomerId"
      v-model="form.customer_id"
      type="text"
      disabled="disabled"
      required
      trim
      placeholder="Customer ID"
    />

    <div
      v-if="showTerms"
      class="register-terms text-center"
    >
      <span> By clicking this button you are accepting to our </span>
      <router-link
        to="/terms"
        target="_blank"
      >
        terms and conditions
      </router-link>
    </div>

    <b-button
      v-if="btnText === 'Account Approved already'"
      variant="success"
    >
      {{ btnText }}
    </b-button>

    <b-button
      v-if="btnText !== 'Account Approved already'"
      type="submit"
      variant="primary"
    >
      {{ btnText }}
    </b-button>
  </b-form>
</template>

<script>

export default {
  name: 'ProfileForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    btnText: {
      type: String,
      default: ''
    },
    showPassword: Boolean,
    showRepresentative: Boolean,
    showCustomerId: Boolean,
    showTerms: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      companyType: [
        { text: 'Type', value: null },
        'Advertiser', 'Agency'
      ],
      states: [
        { text: 'State', value: null },
        'Puerto Rico',
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'District of Columbia',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
      ],
      countries: [
        {
          value: 'USA',
          text: 'United States (USA)'
        }
      ],
      markets_select: [
        { value: null, text: 'Select Market' }
      ],
      account_representatives_select: []
    }
  },

  mounted () {
    console.log(this.form)
    this.$http.get('/representatives/client').then((response) => {
      this.representatives = response.data
      response.data.forEach((d) => {
        if (this.markets_select.indexOf(d.market) === -1) {
          this.markets_select.push(d.market)
        }
      })
    })
  },

  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      this.$emit('submit')
    },
    marketSelected (market) {
      this.account_representatives_select = [{ value: null, text: 'Select Representative' }]
      this.form.account_representative = null

      this.representatives.forEach(r => {
        if (r.market === market) {
          this.account_representatives_select.push(r.name)
        }
      })

      if (market === 'I Don\'t Know') {
        this.form.account_representative = this.account_representatives_select[1]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sub-label {
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 30px;
}

.sub-label.first-sub-label {
  margin-top: 10px;
}

.sub-label .separator {
  flex: 1;
  height: 1px;
  margin-left: 10px;
  background-color: #d0d0d0;
}

.note {
  color: #888888;
  font-size: 12px;
  background-color: #f7f7f7;
  padding: 10px 15px;
  margin-bottom: 15px;
  margin-top: -10px;
  border-radius: 5px;
}

.register-terms {
  font-size: 14px;
  margin: 20px 0 10px;
}

.btn.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn.btn-success:hover {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>

<template>
  <div class="footer">
    <div class="container-fluid">
      <div class="col-md-12 text-center customer-service-wrapper">
        <ul class="menu">
          <router-link
            tag="li"
            to="/"
          >
            Home
          </router-link>
          <router-link
            tag="li"
            to="/register"
          >
            Register
          </router-link>
          <router-link
            tag="li"
            to="/terms"
          >
            Terms and Conditions
          </router-link>
          <router-link
            tag="li"
            to="/contact"
          >
            Contact
          </router-link>
        </ul>
      </div>

      <div class="col-md-12 text-center customer-service-wrapper">
        <div class="customer-service-label">
          Customer Service Email
        </div>
        <div class="customer-service-email">
          creditcards@sbscorporate.com
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .footer {
    background-color: #f7f7f7;
    padding: 40px 0;
    border-top: 1px solid #f1f1f1;
  }

  .footer .customer-service-wrapper {
    color: #000;
  }

  .footer .customer-service-wrapper .customer-service-email {
    font-weight: bold;
    color: #0b60a9;
  }

  .footer .menu {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer .menu li {
    margin: 0 10px;
    cursor: pointer;
  }

  .footer .menu li:hover {
    color: #0b60a9;
  }

  @media (max-width: 768px) {
    .footer .menu li {
      margin: 0 6px;
      font-size: 12px;
    }
  }
</style>

<template>
  <div class="container-fluid">
    <payment-header
      :label="'Pay Invoices'"
      :total="total"
      @add="addRow"
      @process="processPayment"
    />

    <div class="row table-wrapper">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  Invoice #
                </th>
                <th scope="col">
                  Date
                </th>
                <th scope="col">
                  Amount
                </th>
                <th scope="col">
                  Station
                </th>
                <th scope="col">
                  Advertiser
                </th>
                <th v-if="invoices.length > 1" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(invoice, index) in invoices"
                :key="index"
              >
                <td>
                  <b-form-input
                    v-model.trim="invoice.invoiceNumber"
                    :state="errors[index + '.invoiceNumber']"
                  />
                </td>
                <td>
                  <b-form-datepicker
                    v-model="invoice.date"
                    local="en"
                    placeholder="Date"
                    :state="errors[index + '.date']"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </td>
                <td>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span
                        id="basic-addon1"
                        class="input-group-text"
                      >$</span>
                    </div>
                    <b-form-input
                      v-model.number="invoice.amount"
                      required
                      :state="errors[index + '.amount']"
                      number
                      @keypress="$orderInvoiceUtils.isNumber($event)"
                      @paste="$orderInvoiceUtils.handleAmountPaste($event)"
                    />
                  </div>
                </td>
                <td>
                  <station-select
                    :item="invoice"
                    :state="errors[index + '.station']"
                  />
                </td>
                <td>
                  <b-form-input
                    v-model.trim="invoice.advertiser"
                    :state="errors[index + '.advertiser']"
                  />
                </td>
                <td v-if="invoices.length > 1">
                  <button
                    class="btn btn-danger"
                    @click="removeRow(index)"
                  >
                    <i class="fa fa-close" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <payment-modal
      :modal-id="modalID"
      :total="total"
      type="invoices"
    />
  </div>
</template>

<script>
import paymentHeader from './PaymentHeader'
import paymentModal from './PaymentModal'
import StationSelect from '../common/StationSelect'

export default {
  name: 'Invoices',
  components: {
    StationSelect,
    paymentHeader,
    paymentModal
  },

  data () {
    return {
      modalID: 'paymentInvoicesModal',
      transactionID: null,
      invoices: [],
      errors: {}
    }
  },
  computed: {
    total () {
      return this.$orderInvoiceUtils.getTotal(this.invoices)
    }
  },

  mounted () {
    this.invoices = JSON.parse(localStorage.getItem('invoices')) || [{
      invoiceNumber: '',
      date: null,
      amount: 0.00,
      station: '',
      advertiser: ''
    }]
  },

  beforeDestroy () {
    if (this.invoices.length) {
      localStorage.setItem('invoices', JSON.stringify(this.invoices))
    }
  },

  methods: {
    addRow () {
      this.invoices.push({
        invoiceNumber: '',
        date: null,
        amount: null,
        station: '',
        advertiser: ''
      })
    },
    removeRow (index, order) {
      this.invoices.splice(index, 1)
    },
    processPayment () {
      // Validate data and open confirmation modal
      const isValid = this.$orderInvoiceUtils.validateItems(this.invoices, this)
      if (!isValid) {
        return
      }

      localStorage.setItem('invoices', JSON.stringify(this.invoices))
      this.$analyticsManager.addToCart('invoices', this.invoices)
      this.$bvModal.show(this.modalID)
    },
    confirmAndPay () {
      // Redirect user to payment form and close modal
      this.$bvModal.hide(this.modalID)
    }
  }
}
</script>

<style>
#paymentInvoicesModal .modal-content .modal-body {
  padding: 2.5rem 2rem;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-wrapper {
  padding-top: 115px;
}

.modal-text {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}

.modal-text.last {
  margin-bottom: 40px;
  font-size: 36px;
}

.modal-terms {
  font-size: 13px;
}

.btn.btn-custom {
  background-color: #0B60A9;
  padding: 0.575rem 0.75rem;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .table-wrapper {
    padding-top: 105px;
  }
}
</style>

import Amplitude from 'amplitude-js'
const AMPLITUDE_PROD_KEY = 'a56d42c1fc7423a38e0dc02598b598b0'
const AMPLITUDE_DEV_KEY = '2834f3f074f5a5c1e23f6fd5ec1d166a'
const isProdEnvironment = window.location.host === 'payments.spanishbroadcasting.com'
const amplitudeClient = Amplitude.getInstance()

amplitudeClient.init(isProdEnvironment ? AMPLITUDE_PROD_KEY : AMPLITUDE_DEV_KEY)

export default class AnalyticsManager {
  /**
   * Set User Properties
   * @param {object} properties
   */
  setUserProperties (properties) {
    this.logFormatted('setUserProperties', '', properties)
    amplitudeClient.setUserProperties(properties)
  }

  /**
   * Set User ID
   * @param {string} id
   */
  setUserID (id) {
    amplitudeClient.setUserId(id)
  }

  /**
   * Log An Event
   * @param {string} eventName
   * @param {object} properties
   */
  logEvent (eventName, properties) {
    this.logFormatted('deliveredPayloadToAmplitude', eventName, properties)
    amplitudeClient.logEvent(eventName, properties)
  }

  viewedPage () {
    const eventProperties = {
      pageTitle: document.title || '',
      domain: window.location.host,
      protocol: window.location.protocol === 'https:' ? 'HTTPS' : 'HTTP',
      path: window.location.pathname
    }
    this.logEvent('ViewedPage', eventProperties)
  }

  /**
   * Add To Cart
   * @param {string} type
   * @param {Array<{}>} items
   */
  addToCart (type, items) {
    this.logEvent('AddedToCart', {
      typeToPay: type,
      items: items
    })
  }

  /**
   *
   * @param {string} callPrefix
   * @param {string} eventName
   * @param {object} properties
   */
  logFormatted (callPrefix, eventName, properties) {
    this.log(`${callPrefix} (${eventName}): \n ${JSON.stringify(properties, null, 2)}`)
  }

  log (content) {
    window.console.log(`%c Amplitude.${content}`, 'color: blue')
  }
}

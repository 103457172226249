// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App'
import Toaster from 'v-toaster'
import Http from './components/common/http.js'
import Notifications from 'vue-notification'
import router from './router'
import VueCurrencyFilter from 'vue-currency-filter'
import VueMask from 'v-mask'
import AnalyticsManager from './components/common/AnalyticsManager'
import Modal from './components/common/Modal'
import OrderInvoiceUtils from './components/common/OrderInvoiceUtils'
import ScriptLoader from './components/common/script-loader'
require('vue-ionicons/ionicons.css')

Vue.config.productionTip = false
Vue.prototype.$http = new Http()
Vue.prototype.$analyticsManager = new AnalyticsManager()
Vue.prototype.$orderInvoiceUtils = new OrderInvoiceUtils()
Vue.prototype.$orderInvoiceUtils = new OrderInvoiceUtils()

/**
 * Notify with Modal
 * @param {Object} options
 * @param {string} options.type "success" or "error"
 * @param {string} options.title title of notification
 * @param {string} options.html html of body
 * @param {string} options.confirmButtonText button text
 */
Vue.prototype.$notifyModal = function (options) {
  const ComponentClass = Vue.extend(Modal)
  const instance = new ComponentClass({
    propsData: options
  })
  instance.$slots.default = [options.html]
  instance.$mount()
}

Vue.use(BootstrapVue)
Vue.use(Toaster)
Vue.use(Notifications)
Vue.use(VueMask)
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

/* eslint-disable no-new */
new ScriptLoader().load(process.env.VUE_APP_CHECKOUT_JS_SCRIPT)
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

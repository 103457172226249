<template>
  <div class="container-fluid">
    <payment-header
      :label="'Pay Orders'"
      :total="total"
      @add="addRow"
      @process="processPayment"
    />

    <div class="row table-wrapper">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  Order #
                </th>
                <th scope="col">
                  Amount
                </th>
                <th scope="col">
                  Station
                </th>
                <th scope="col">
                  From
                </th>
                <th scope="col">
                  To
                </th>
                <th scope="col">
                  Advertiser
                </th>
                <th v-if="orders.length > 1" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in orders"
                :key="index"
              >
                <td>
                  <b-form-input
                    v-model.trim="order.orderNumber"
                    :state="errors[index + '.orderNumber']"
                  />
                </td>
                <td>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span
                        id="basic-addon1"
                        class="input-group-text"
                      >$</span>
                    </div>
                    <b-form-input
                      v-model.number="order.amount"
                      required
                      :state="errors[index + '.amount']"
                      number
                      @keypress="$orderInvoiceUtils.isNumber($event)"
                      @paste="$orderInvoiceUtils.handleAmountPaste($event)"
                    />
                  </div>
                </td>
                <td>
                  <station-select
                    :item="order"
                    :state="errors[index + '.station']"
                  />
                </td>
                <td>
                  <b-form-datepicker
                    v-model="order.from"
                    local="en"
                    placeholder="Date"
                    :state="errors[index + '.from']"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </td>
                <td>
                  <b-form-datepicker
                    v-model="order.to"
                    local="en"
                    placeholder="Date"
                    :state="errors[index + '.to']"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </td>
                <td>
                  <b-form-input
                    v-model.trim="order.advertiser"
                    :state="errors[index + '.advertiser']"
                  />
                </td>
                <td v-if="orders.length > 1">
                  <button
                    class="btn btn-danger"
                    @click="removeRow(index)"
                  >
                    <i class="fa fa-close" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <payment-modal
      :modal-id="modalID"
      :total="total"
      type="orders"
    />
  </div>
</template>

<script>
import paymentHeader from './PaymentHeader'
import paymentModal from './PaymentModal'
import StationSelect from '../common/StationSelect'

export default {
  name: 'Orders',
  components: {
    paymentHeader,
    paymentModal,
    StationSelect
  },
  data () {
    return {
      modalID: 'paymentOrdersModal',
      transactionID: null,
      orders: [],
      errors: {}
    }
  },

  computed: {
    total () {
      return this.$orderInvoiceUtils.getTotal(this.orders)
    }
  },

  mounted () {
    this.orders = JSON.parse(localStorage.getItem('orders')) || [{
      orderNumber: '',
      amount: 0.00,
      station: '',
      from: null,
      to: null,
      advertiser: ''
    }]
  },

  beforeDestroy () {
    if (this.orders.length) {
      localStorage.setItem('orders', JSON.stringify(this.orders))
    }
  },

  methods: {
    addRow () {
      this.orders.push({
        orderNumber: '',
        amount: null,
        station: '',
        from: null,
        to: null,
        advertiser: ''
      })
    },
    removeRow (index) {
      this.orders.splice(index, 1)
    },
    processPayment () {
      // Validate data and open confirmation modal
      const isValid = this.$orderInvoiceUtils.validateItems(this.orders, this)
      if (!isValid) {
        return
      }
      localStorage.setItem('orders', JSON.stringify(this.orders))
      this.$analyticsManager.addToCart('orders', this.orders)
      this.$bvModal.show(this.modalID)
    }
  }
}
</script>

<style>
#paymentOrdersModal .modal-content .modal-body {
  padding: 2.5rem 2rem;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-wrapper {
  padding-top: 115px;
}

@media (max-width: 768px) {
  .table-wrapper {
    padding-top: 105px;
  }
}
</style>

<template>
  <div class="page-wrapper">
    <div class="header-wrapper">
      <div class="overlay" />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <img
                src="../../assets/sbs-blue-logo.svg"
                alt="La Musica"
              >
              <div class="text">
                SBS Payment Center
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container-not-found">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="not-found-wrapper">
            <h1>404</h1>
            <h2>Sorry. Page not found</h2>
            <router-link
              to="/"
              class="btn btn-lg btn-primary m-2"
            >
              Click here to go home
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import mainFooter from './Footer'
export default {
  name: 'NotFound',
  components: {
    mainFooter
  },
  head: {
    title: function () {
      return {
        inner: 'Page Not Found'
      }
    }
  }
}
</script>

<style scoped>
  .header-wrapper {
    background-image: url('../../assets/home-bg.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 20px 0;
  }

  .header-wrapper img {
    max-width: 200px;
    margin-bottom: 15px;
  }

  .header-wrapper .text {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .not-found-wrapper {
    text-align: center;
    margin-bottom: 30px;
  }

  .not-found-wrapper h1 {
    font-size: 180px;
  }

  .not-found-wrapper a {
    margin-top: 20px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 20px;
  }
</style>

<template>
  <div id="app">
    <router-view />

    <notifications
      group="notifications"
      classes="toast-notification"
      position="bottom right"
    />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700');

* {
  margin: 0;
  padding: 0;
}

ol, ul, dl, p {
  margin: 0;
  padding: 0;
}

input,
textarea {
  box-shadow: none;
}

.btn-secondary {
  background-color: #a0a0a0;
}

.container-fluid {
  max-width: 1400px;
}

.table-responsive {
  min-height: 550px;
}

.table th, .table td {
  min-width: 115px;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 1.6;
  color: #7d7d7d;
  background: #fff;
  overflow: hidden;
  /* min-height: 700px; */
}

.table thead th {
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  font-family: 'Poppins', sans-serif;;
}

 .btn.btn-primary {
  background-color: #0B60A9;
}

.bootstrap-form input,
.bootstrap-form .dateInput,
.bootstrap-form select, .dateInput {
  display: block;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px !important;
  height: 45px !important;
  font-size: 15px;
  border:1px solid #eaeaea;
}

.bootstrap-form {
  textarea{
    display: block;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 0;
    font-size: 14px;
    border:1px solid #eaeaea;
    border-radius: 0.25rem;
  }
  button{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #0B60A9;
    border: none;
    width: 100%;

    &:hover,
    &:active,
    &:active:focus {
      background-color: #08487e !important;
      border: none !important;
    }
  }

  .custom-file-label {
    border-radius: 0;
  }
}

.vue-notification-group {
  width: auto !important;
  max-width: 600px;

}

.toast-notification {
  padding: 20px 15px;
  margin: 10px;
  font-size: 16px;
  color: #fff;
  background: #0B60A9;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  .notification-content {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
  }

  &.warn {
    background: #f48a06;
  }

  &.error {
    background: #de4242;
  }

  &.success {
    background: #0B60A9;
  }
}

@media (max-width: 768px) {
  .menu-inner-wrapper .menu-desktop {
    display: none;
  }

  .menu-mobile-icon,
  .menu-mobile {
    display: block;
  }
}
</style>
